import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { Link } from "../components/link";

import Half8Solid from "../icons/half-8-solid.svg";
import Half2Solid from "../icons/half-2-solid.svg";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />

    <div className="page-not-found">
      <div className="container-lg">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 pt-md-5 pb-5 pb-md-0">
            <div className="d-flex align-items-center py-5">
              <div>
                <h1>Pagina non trovata!</h1>
                <p>La pagina che cerchi non è disponibile.</p>
                <Link to="/" title="Torna alla homepage" className="btn-link mt-3">
                  Torna alla homepage
                </Link>
              </div>
              <div className="d-flex align-items-center ms-auto">
                <Half8Solid className="ico-svg ico-half-8-solid" />
                <Half2Solid className="ico-svg ico-half-2-solid ms-2 ms-md-5" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
